import { IAppState } from '../../../../common/state';
import { EInfoForTgbActions } from '../actionTypes';
import { FetchNewbuildingsInfoForTgbSuccess } from '../setNewbuildingsInfoForTgb';
import { IBannerEnrichments } from 'shared/repositories/ad-banner/entities/get_banner_enrichments_v1/BannerEnrichments';

export type InfoForTgbState = {
  jkInfoForTgbCollection: Record<number, IBannerEnrichments>;
};

export function infoForTgbReducer(state: IAppState, action: FetchNewbuildingsInfoForTgbSuccess): IAppState {
  switch (action.type) {
    case EInfoForTgbActions.fetchNewbuildingsInfoForTgbSuccess:
      return {
        ...state,
        infoForTgb: {
          ...state.infoForTgb,
          jkInfoForTgbCollection: action.payload,
        },
      };
    default:
      return state;
  }
}
