import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TgbNativedescGeneral, TgbNativedescGeneralProps } from './TgbNativedescGeneral';
import { selectJsonQuery } from '../../../../../../selectors/results';
import { fetchGetNewbuildingsForSerpService } from '../../../../../../services/fetchGetNewbuildingsForSerpService';
import { jsonRegionIdToTransportType } from '../../../../../../utils/adfox/jsonRegionIdToTransportType';
import { useApplicationContext } from '../../../../../../utils/applicationContext';
import { IBannerEnrichments } from 'shared/repositories/ad-banner/entities/get_banner_enrichments_v1/BannerEnrichments';
import {
  EDealType,
  EObjectType,
} from 'shared/repositories/ad-banner/entities/get_banner_enrichments_v1/GetBannerEnrichmentsV1Request';

type TgbNativedescGeneralContainerProps = {
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: FC<TgbNativedescGeneralContainerProps> = props => {
  const { context } = props;

  const {
    annotation,
    image,
    url,
    metroDistance,
    metroColor,
    metro,
    description,
    title,
    location,
    button,
    label,
    logoImageUrl,
    builderName,
    newBuildingId,
    newBuildingIdIsUsed,
    objectId,
    objectType,
    dealType,
    useServerEnrichment,
  } = context;

  const applicationContext = useApplicationContext();

  const [newbuidingInfoForTgb, setNewbuidingInfoForTgb] = useState<IBannerEnrichments | undefined>();

  const jsonQuery = useSelector(selectJsonQuery);

  const newBuildingIdInt = newBuildingId ? parseInt(newBuildingId, 10) : null;
  const objectIdInt = objectId ? parseInt(objectId, 10) : newBuildingIdInt;

  /**
   * @description Только для значений newBuildingIdIsUsed === '1' и useServerEnrichment === '1' делаем запрос на бекенд для обогащения данных
   */
  const isEnrichmentFromBackendEnabled = newBuildingIdIsUsed === '1' || useServerEnrichment === '1';

  useEffect(() => {
    if (isEnrichmentFromBackendEnabled && objectIdInt && !newbuidingInfoForTgb) {
      fetchGetNewbuildingsForSerpService(applicationContext, {
        objectId: objectIdInt,
        dealType: dealType as EDealType,
        objectType: objectType as EObjectType,
      })
        .then(data => {
          setNewbuidingInfoForTgb(data.enrichments);
        })
        .catch(() => {});
    }
    /**
     * @description Тут смотрим на изменение objectIdInt, изменение остальных данных не должно триггерить запрос
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectIdInt]);

  const tgbNativedescGeneralProps = ((): TgbNativedescGeneralProps | null => {
    if (isEnrichmentFromBackendEnabled) {
      if (newbuidingInfoForTgb) {
        return {
          address: newbuidingInfoForTgb.address,
          annotation,
          builderName: newbuidingInfoForTgb.publisher.name,
          button,
          description,
          image,
          label,
          logoImageUrl: newbuidingInfoForTgb.publisher.logo.url,
          title,
          transports: newbuidingInfoForTgb.transports,
          url,
        };
      }

      return null;
    }

    const transports: TgbNativedescGeneralProps['transports'] = [];

    if (metro) {
      const iconType = jsonRegionIdToTransportType(jsonQuery);

      transports.push({
        iconColor: metroColor,
        iconType,
        id: 1,
        name: metro,
        remoteness: metroDistance,
      });
    }

    return {
      logoImageUrl,
      url,
      transports,
      label,
      button,
      image,
      annotation,
      builderName,
      title,
      address: location,
      description,
    };
  })();

  if (tgbNativedescGeneralProps) {
    return <TgbNativedescGeneral {...tgbNativedescGeneralProps} />;
  }

  return null;
};
